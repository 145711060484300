<template>
  <v-container class="not-found-page p-tb-2">
    <div class="container">
      <div class="heading">
        <span>OOPS...</span>
        <h1>Page Not Found</h1>
      </div>
      <div class="code-wrapper">
        <div class="error-code">404</div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style type="text/css" lang="scss">
.not-found-page {
  .heading {
    text-align: center;
    text-transform: uppercase;

    span {
      font-size: 2rem;
      font-weight: 500;
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: small;
    }
  }
  .code-wrapper {
    text-align: center;

    .error-code {
      font-size: 20rem;
      font-weight: bold;
      color: #e7e7e7;
    }
  }
}

@media only screen and (max-width: 640px) {
  .code-wrapper .error-code {
    font-size: 10rem !important;
    margin: 1rem 0;
  }
}
</style>
